<div class="row px-3 pt-2 content">
    <div [formGroup]="searchForm" *ngIf="showFilterPanel" class="card p-0 col-3">
        <div  class="card-body filters-card p-2 px-4">
            <div class="row mt-2 d-flex align-items-center justify-content-between">
                <h5>Generate Report</h5>
            </div>
            <p>Report Criteria</p>
            <mat-button-toggle-group formControlName="searchCriteria" name="report" class="w-100 d-flex">
                <mat-button-toggle value="all" class="flex-fill">All</mat-button-toggle>
                <mat-button-toggle value="description" class="flex-fill">Description</mat-button-toggle>
                <mat-button-toggle value="keywords" class="flex-fill">Keywords</mat-button-toggle>
                <mat-button-toggle value="content" class="flex-fill">Content</mat-button-toggle>
            </mat-button-toggle-group>
            <div class="text-center mt-3">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Search</mat-label>
                    <input matInput type="text" formControlName="query" autocomplete="off" placeholder="Query..."
                    (keydown.enter)="search()">
                    <button *ngIf="searchForm.get('query').value" matSuffix mat-icon-button (click)="resetSearchInput()">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div class="d-flex justify-content-end">
                <button color="primary" (click)="search()" mat-flat-button>
                    <mat-icon matSuffix>start</mat-icon>
                    Run Report
                </button>
            </div>
        </div>
    </div>
    <div class="col-9 ps-3" [class.col-12]="!showFilterPanel">
        <div class="d-flex align-items-center justify-content-between">
            <button (click)=toggleDisplay() mat-icon-button [matTooltip]="(showFilterPanel ? 'Hide' : 'Show') + ' filters'"
                    matTooltipPosition="after"><mat-icon>menu</mat-icon></button>
            <h5>Reporting</h5>
            <div class="button">
                <button color="primary" mat-flat-button [disabled]="(!results.data || results.data.length === 0)" (click)="downloadCSV()">
                    <mat-icon>file_download</mat-icon>
                    Export as CSV</button>
            </div>
        </div>
        <div class="table-container">
            <table class="ctm-table w-100 pt-2">
                <tr class="text-center" *ngIf="loading">
                    Loading requested data, please wait.
                </tr>
                <tr *ngIf="loading">
                    <mat-progress-bar color="warn" mode="indeterminate"></mat-progress-bar>
                </tr>
                <tr *ngIf="!results.data && !loading">
                    <td class="text-center info-row">Select Filters and Run Report to show data</td>
                </tr>
            </table>
            <mat-card *ngIf="results.data">
                <mat-card-title>
                    <span>Results</span>
                </mat-card-title>
                    <mat-table [dataSource]="dataSource">
                        <!-- Define columns -->
                        <ng-container *ngFor="let column of results?.columns; let i = index" [matColumnDef]="column">
                            <mat-header-cell *matHeaderCellDef>{{ column | titleCase }}</mat-header-cell>
                            <mat-cell *matCellDef="let row">{{ row[i] }}</mat-cell>
                        </ng-container>

                        <!-- Header row -->
                        <mat-header-row *matHeaderRowDef="results?.columns"></mat-header-row>
                        <!-- Data rows -->
                        <mat-row routerLink="/document/{{row[1]}}/info" *matRowDef="let row; columns: results?.columns;"></mat-row>
                        <tr class="d-flex" *matNoDataRow>
                            <td class="mat-cell w-100" [attr.colspan]="20">
                                <div class="no-data-row">
                                    <mat-icon color="accent">info_outline</mat-icon>
                                    <div>Current filters returned 0 results</div>
                                </div>
                            </td>
                        </tr>
                    </mat-table>
                    <mat-paginator *ngIf="results.data" [pageSizeOptions]="[5, 10, 25, 50]" pageSize="15" showFirstLastButtons></mat-paginator>
            </mat-card>
        </div>
    </div>
</div>


