import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '@app/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppSetting, AuthRequest, AuthResponse} from '@app/models';
import {Log} from '@app/helpers';
import {AppSettingsService} from '@app/services/app-settings.service';
import {ErrorService} from '@app/services/error.service';
import {ScriptService} from '@app/services/script.service';
import {ClientSettingsService} from '@app/services/client-settings.service';
import {ClientSettings} from '@app/models/client-settings.model';


declare const window: Window & {
    onGoogleLibraryLoad: () => void;
};

@Component({
    selector: 'devfu-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    @ViewChild('googleSignIn', {read: ElementRef, static: true}) googleSignInRef!: ElementRef;

    loginData: AuthRequest = {username: '', password: ''};
    error: any;
    isLoading = false;
    prettifyError = this.errorService.prettifyError;
    showSessionExpiredMessage = false;
    settings: AppSetting = new AppSetting();
    googleLibraryLoaded = false;

    private clientSettings: ClientSettings;

    constructor(public router: Router,
                public authService: AuthService,
                public appSettingsService: AppSettingsService,
                public errorService: ErrorService,
                public activatedRoute: ActivatedRoute,
                private scriptService: ScriptService,
                private ngZone: NgZone,
                private clientSettingsService: ClientSettingsService) {

    }

    onLogin() {
        if (this.isLoading || !this.loginData.password.length) {
            return;
        }
        this.error = null;
        this.authService.resetStorage();
        this.isLoading = true;
        this.authService.authenticateWithServer(this.loginData).subscribe({
            next: (data: AuthResponse) => {
                this.authService.setTokenInStorage(data.access_token);
                this.authService.setLoginStateInStorage(true);
                this.authService.getIdentityFromServer().subscribe(identity => {
                    this.isLoading = false;
                    this.authService.setIdentityInStorage(identity);
                    if (this.authService.redirectUrl) {
                        this.router.navigate([this.authService.redirectUrl]).then();
                        this.authService.redirectUrl = null;
                    } else {
                        this.router.navigate(['/dashboard']).then();
                    }
                });

            },
            error: error => {
                this.authService.resetStorage();
                this.error = error;
                this.isLoading = false;
            }
        });

    }

    ngOnInit(): void {
        if (this.authService.isAuthenticated() && this.authService.getIdentityFromStorage()) {
            this.router.navigateByUrl('');
            return;
        }

        this.clientSettings = this.clientSettingsService.getClientSettings();

        if (this.clientSettings.googleLoginEnabled) {
            this.injectGoogleScript();
        }

        this.activatedRoute.queryParams.subscribe(queryParams => {
            if (queryParams.hasOwnProperty('session_expired')) {
                if (queryParams['session_expired'] === 'true') {
                    this.showSessionExpiredMessage = true;
                }
            }
        });

        this.appSettingsService.getAll().subscribe(settings => {
            this.settings = settings;
        });
    }

    injectGoogleScript() {
        // need to inject script on reload as that is when javascript will be run when loaded
        this.scriptService.load('google').then();
        window.onGoogleLibraryLoad = () => {
            this.googleLibraryLoaded = true;
            this.initGoogleSignIn();
        };
    }

    initGoogleSignIn() {
        // @ts-ignore
        google.accounts.id.initialize({
            // Ref: https://developers.google.com/identity/gsi/web/reference/js-reference#IdConfiguration
            client_id: this.clientSettings.googleClientID,
            callback: this.handleCredentialResponse.bind(this), // Whatever function you want to trigger...
            cancel_on_tap_outside: false
        });

        // @ts-ignore
        google.accounts.id.renderButton(this.googleSignInRef.nativeElement, {
            type: 'standard',
            theme: 'outline',
            size: 'large',
            shape: 'rectangular',
            logo_alignment: 'left'
        });

        // @ts-ignore
        google.accounts.id.prompt();
    }

    handleCredentialResponse(response: any) {
        this.ngZone.run(() => {
            this.authService.doGoogleLogin(response.credential, this.clientSettings.googleClientID)
                .then(() => {
                    this.router.navigate(['']).then();
                }).catch((err: any) => {
                Log.e('There was an error logging in.');
                this.error = err;
                setTimeout(() => {
                    this.error = '';
                }, 5000);
            });
        });
    }
}
