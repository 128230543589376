import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService, BreadcrumbService } from '@app/core';
import { environment as env } from '@env/environment';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoadingState } from '@app/core/loading-state.enum';
import { Atlas, Document, ReviewRequest, User } from '@app/models';
import { lastValueFrom, Subscription } from 'rxjs';
import { DocumentService } from '@app/services/document.service';
import { AtlasService } from '@app/services/atlas.service';
import { ReviewRequestService } from '@app/services/review-request.service';
import { AppSettingsService } from '@app/services/app-settings.service';

@Component({
    selector: 'devfu-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

    @ViewChild('revisionNeededSort') revisionNeededSort: MatSort;
    @ViewChild('newDocumentsSort') newDocumentsSort: MatSort;
    @ViewChild('draftDocumentsSort') draftDocumentsSort: MatSort;
    @ViewChild('reviewRequestsSort') reviewRequestsSort: MatSort;

    authSubscription: Subscription;
    isAuthenticated = false;
    identity: User;
    version = env.versions.app;
    expiringDocuments: Document[];
    newDocuments: Document[];
    draftDocuments: Document[];
    reviewRequests: ReviewRequest[];
    dateFormat = env.defaultDateFormat;
    revisionDocumentState: LoadingState = LoadingState.Loading;
    revisionDateFormat = env.revisionDateFormat;
    revisionNeededDataSource: MatTableDataSource<Document>;
    newDocumentsDataSource: MatTableDataSource<Document>;
    newDocumentsState: LoadingState = LoadingState.Loading;
    draftDocumentsDataSource: MatTableDataSource<Document>;
    draftDocumentsState: LoadingState = LoadingState.Loading;
    reviewRequestsDataSource: MatTableDataSource<ReviewRequest>;
    reviewRequestsState: LoadingState = LoadingState.Loading;
    firstAtlasId: number;
    privateAtlases: Atlas[] = [];
    publicAtlases: Atlas[] = [];

    // large screens
    revisionNeededDisplayedColumns = ['title', 'version_format', 'revision_due', 'owner'];
    newDocumentsDisplayedColumns = ['title', 'version_format', 'date_added', 'upload_user'];
    draftDocumentsDisplayedColumns = ['title', 'version_format', 'date_added'];
    reviewRequestsDisplayedColumns = ['title', 'version_title', 'type', 'request_time', 'requestor'];

    smallRevisionNeededDisplayedColumns = ['title', 'owner'];
    smallDraftsDisplayedColumns = ['title', 'upload_format_draft'];
    smallNewDocumentsDisplayedColumns = ['title', 'upload_user_new'];
    smallReviewRequestsDisplayedColumns = ['title', 'type'];

    canCreateDocument = false;

    constructor(
        private router: Router,
        private documentService: DocumentService,
        private authService: AuthService,
        private atlasService: AtlasService,
        private breadcrumbService: BreadcrumbService,
        private reviewRequestService: ReviewRequestService,
        private settingsService: AppSettingsService) {
    }

    // Expose LoadingState to template
    get LoadingState() {
        return LoadingState;
    }

    ngOnInit() {
        this.settingsService.getAll().subscribe(s => {
            if (s.everyone_creates_documents) {
                this.canCreateDocument = true;
            } else {
                this.authService.checkIsEditor().subscribe(isEditor => {
                    this.canCreateDocument = isEditor;
                });
            }
        });
        const handleAuth = async () => {
            this.isAuthenticated = this.authService.getLoginStateFromStorage();

            if (this.isAuthenticated) {
                // this.identity = await this.authService.getIdentityFromServer().toPromise();
                this.identity = await lastValueFrom(this.authService.getIdentityFromServer());
                this.loadData();
            }
        };

        this.authSubscription = this.authService.onStateUpdated.subscribe(handleAuth);
        handleAuth().then();

        this.breadcrumbService.setCurrentPath([
            { label: 'Dashboard', path: null }
        ]);
    }

    ngOnDestroy(): void {
        this.authSubscription.unsubscribe();
    }

    loadData() {
        // Load all new documents (10 max)
        this.documentService.getExpiring()
            .subscribe({
                next: (documents: Document[]) => {
                    this.expiringDocuments = documents.slice(0, 10);
                    this.revisionNeededDataSource = new MatTableDataSource(this.expiringDocuments);
                    this.revisionNeededDataSource.sort = this.revisionNeededSort;
                    if (documents.length) {
                        this.revisionDocumentState = LoadingState.LoadedOK;
                    } else {
                        this.revisionDocumentState = LoadingState.LoadedNoData;
                    }
                },
                error: () => {
                    this.revisionDocumentState = LoadingState.ErrorLoading;
                }
            });

        // Load all new documents (10 max)
        this.documentService.getLatest()
            .subscribe({
                next: (documents: Document[]) => {
                    this.newDocuments = documents.slice(0, 10);
                    this.newDocumentsDataSource = new MatTableDataSource(this.newDocuments);
                    this.newDocumentsDataSource.sort = this.newDocumentsSort;
                    if (documents.length) {
                        this.newDocumentsState = LoadingState.LoadedOK;
                    } else {
                        this.newDocumentsState = LoadingState.LoadedNoData;
                    }
                },
                error: () => {
                    this.newDocumentsState = LoadingState.ErrorLoading;
                }
            });

        this.documentService.getUserDrafts()
            .subscribe({
                next: (documents: Document[]) => {
                    this.draftDocuments = documents;
                    this.draftDocumentsDataSource = new MatTableDataSource(this.draftDocuments);
                    this.draftDocumentsDataSource.sort = this.draftDocumentsSort;
                    if (documents.length) {
                        this.draftDocumentsState = LoadingState.LoadedOK;
                    } else {
                        this.draftDocumentsState = LoadingState.LoadedNoData;
                    }
                },
                error: () => {
                    this.draftDocumentsState = LoadingState.ErrorLoading;
                }
            });

        this.reviewRequestService.getOpenForUser().subscribe({
                next: requests => {
                    this.reviewRequests = requests.slice(0, 10);
                    this.reviewRequestsDataSource = new MatTableDataSource(this.reviewRequests);
                    this.reviewRequestsDataSource.sort = this.reviewRequestsSort;
                    if (this.reviewRequests.length) {
                        this.reviewRequestsState = LoadingState.LoadedOK;
                    } else {
                        this.reviewRequestsState = LoadingState.LoadedNoData;
                    }
                },
                error: () => {
                    this.reviewRequestsState = LoadingState.ErrorLoading;
                }
            }
        );

        // Get first atlas ID
        this.atlasService.getAll().subscribe((atlases: Atlas[]) => {
            if (atlases.length > 0) {
                this.firstAtlasId = atlases[0].id;
            }
            this.privateAtlases = atlases.filter(a => a.is_private);
            this.publicAtlases = atlases.filter(a => !a.is_private);
        });
    }

    viewDocument(id: number) {
        this.router.navigate([`/document/${id}`]);
    }

    viewReviewRequest(requestID: number, documentID: number) {
        this.router.navigate([`/document/${documentID}/info`], { queryParams: { request: requestID } });
    }

    isExpired(date: string|number|Date): boolean {
        return new Date(date).getTime() < new Date().getTime();
    }
}
